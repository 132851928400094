import './config/publicPath'; // Should be at the very beginning of entry

import domReady from '@wordpress/dom-ready';
import detectTouchSupport from './modules/detectTouchSupport';
import searchAndFilters from './modules/searchAndFilters';
import dropdown from './modules/dropdown';
import toggleFilters from './modules/toggleFilters';
import mobilemobileMenu from './modules/mobileMenu';
import goBack from './modules/goBack';
import gallerySlider from './modules/gallerySlider';
import relatedLessons from './modules/relatedLessons';
import instruction from './modules/instruction';
import startButton from './modules/startButton';
import instructionsButton from './modules/instructionsButton';

domReady(() => {
  detectTouchSupport();
  mobilemobileMenu();
  searchAndFilters();
  dropdown();
  toggleFilters();
  goBack();
  gallerySlider();
  relatedLessons();
  instruction();
  startButton();
  instructionsButton();
});
