export default () => {
  const toggle = document.querySelector('.toggle-filters');
  const wrapper = document.querySelector('.toggle-filters__wrapper');
  const filters = document.querySelector('.filters');

  if (toggle) {
    toggle.addEventListener('click', () => {
      wrapper.classList.toggle('toggled');
      filters.classList.toggle('open');
    });
  }
};
