/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';

export default () => {
  const FilterableLessonList = {
    offset: 0,
    PER_PAGE: 9,
    isPopstateEvent: false,
    gradeTerms: siteData.gradeTerms,
    subjectTerms: siteData.subjectTerms,
    topicTerms: siteData.topicTerms,
    wpTotalPages: 0,
    wpTotal: 0,

    init() {
      this.wrapper = document.querySelector('.lessons');
      if (this.wrapper === null) {
        return;
      }

      // this.filters = document.querySelectorAll('.dropdown');
      this.button = document.querySelector('.load-more__btn');
      // this.filterGrades = document.getElementById('filter-grades');
      this.filterGrades = document.getElementById('dropdown__options-grade');
      this.buttonGrades = document.getElementById('dropdown__button-grade');
      this.gradeOptions = this.filterGrades.querySelectorAll('[role="option"]');

      // this.filterSubjects = document.getElementById('filter-subjects');
      this.filterSubjects = document.getElementById(
        'dropdown__options-subject'
      );
      this.buttonSubjects = document.getElementById('dropdown__button-subject');
      this.subjectOptions =
        this.filterSubjects.querySelectorAll('[role="option"]');

      // this.filterTopics = document.getElementById('filter-topics');
      this.filterTopics = document.getElementById('dropdown__options-topic');
      this.buttonTopics = document.getElementById('dropdown__button-topic');
      this.topicOptions = this.filterTopics.querySelectorAll('[role="option"]');

      this.searchForm = document.querySelector('.search-form');
      this.searchInput = document.querySelector('.search-form__input');
      this.searchLabel = document.querySelector('.search-form__label');

      this.searchReset = document.querySelector('.search-form__reset');
      this.dropdowns = document.querySelector('.dropdowns');

      this.grade = this.buttonGrades.value;
      // this.grade = this.filterGrades.getAttribute('data-value');
      this.subject = this.buttonSubjects.value;
      // this.subject = this.filterSubjects.getAttribute('data-value');
      this.topic = this.buttonTopics.value;
      // this.topic = this.filterTopics.getAttribute('data-value');
      this.search = this.searchInput.value;

      this.initEvents();
      this.initMutationObserver();
    },

    initEvents() {
      // this.initFilterGradeEvents();
      // this.initFilterSubjectEvents();
      // this.initFilterTopicEvents();

      this.initLoadMoreEvents();
      this.initPopstateEvents();
      this.initSearchFormEvents();
    },

    initMutationObserver() {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((record) => {
          const { id } = record.target;

          if (record.type === 'attributes') {
            const changedAttrName = record.attributeName;
            const newValue = record.target.getAttribute(changedAttrName);

            if (changedAttrName === 'value') {
              switch (id) {
                case 'dropdown__button-grade':
                  this.grade = newValue;
                  this.onFilterChange('grade', this.isPopstateEvent);
                  break;
                case 'dropdown__button-subject':
                  this.subject = newValue;
                  this.onFilterChange('subject', this.isPopstateEvent);
                  break;
                case 'dropdown__button-topic':
                  this.topic = newValue;
                  this.onFilterChange('topic', this.isPopstateEvent);
                  break;
                default:
                  break;
              }
            }
          }
        });
      });

      observer.observe(this.dropdowns, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    },

    initFilterGradeEvents() {
      if (this.filterGrades) {
        this.filterGrades.addEventListener('click', (event) => {
          // this.grade = this.filterGrades.value;
          this.grade = event.target.getAttribute('data-value');
          this.filterGrades.setAttribute('data-value', this.grade);

          // console.log('this.grade', this.grade);

          this.onFilterChange('grade');
        });
      }
    },

    initFilterSubjectEvents() {
      if (this.filterSubjects) {
        this.filterSubjects.addEventListener('click', (event) => {
          // this.subject = this.filterSubjects.value;
          this.subject = event.target.getAttribute('data-value');

          // console.log('this.subject', this.subject);

          this.onFilterChange('subject');
        });
      }
    },

    initFilterTopicEvents() {
      if (this.filterTopics) {
        this.filterTopics.addEventListener('click', (event) => {
          // this.topic = this.filterTopics.value;
          this.topic = event.target.getAttribute('data-value');

          // console.log('topic', this.topic);

          this.onFilterChange('topic');
        });
      }
    },

    initLoadMoreEvents() {
      if (this.button) {
        this.button.addEventListener('click', async () => {
          this.offset += this.PER_PAGE;

          const data = await this.getData(
            this.offset,
            this.grade,
            this.subject,
            this.topic,
            this.search
          );
          if (data.length) {
            const html = this.getPostsHtml(data);
            this.wrapper.innerHTML += html;
            if (!this.hasMoreLessons()) {
              this.button.style.display = 'none';
            }
          } else {
            this.button.style.display = 'none';
          }
        });
      }
    },

    initPopstateEvents() {
      window.addEventListener('popstate', async () => {
        this.isPopstateEvent = true;

        const url = new URL(window.location);
        const gradeFromUrl = url.searchParams.get('grade') || '0';
        const subjectFromUrl = url.searchParams.get('subject') || '0';
        const topicFromUrl = url.searchParams.get('topic') || '0';
        const search = url.searchParams.get('search') || null;

        if (gradeFromUrl === '0') {
          this.buttonGrades.innerHTML = `<span 
            class="dropdown__button-text">${siteData.grade}</span>
            <span class="dropdown__button-txt-wrap">${siteData.allGrades}</span>`;
          this.buttonGrades.setAttribute('value', '0');
        } else {
          const term = this.gradeTerms.find(
            (item) => item.term_id.toString() === gradeFromUrl
          );
          if (term) {
            this.buttonGrades.innerHTML = `<span 
              class="dropdown__button-text">${siteData.grade}</span>
              <span class="dropdown__button-txt-wrap">${term.name}</span>`;
          }
          this.buttonGrades.setAttribute('value', gradeFromUrl);
        }

        if (subjectFromUrl === '0') {
          this.buttonSubjects.innerHTML = `<span 
            class="dropdown__button-text">${siteData.subject}</span>
            <span class="dropdown__button-txt-wrap">${siteData.allSubjects}</span>`;
          this.buttonSubjects.setAttribute('value', '0');
        } else {
          const term = this.subjectTerms.find(
            (item) => item.term_id.toString() === subjectFromUrl
          );
          if (term) {
            this.buttonSubjects.innerHTML = `<span 
              class="dropdown__button-text">${siteData.subject}</span>
              <span class="dropdown__button-txt-wrap">${term.name}</span>`;
          }
          this.buttonSubjects.setAttribute('value', subjectFromUrl);
        }

        if (topicFromUrl === '0') {
          this.buttonTopics.innerHTML = `<span 
            class="dropdown__button-text">${siteData.topic}</span>
            <span class="dropdown__button-txt-wrap">${siteData.allTopics}</span>`;
          this.buttonTopics.setAttribute('value', '0');
        } else {
          const term = this.topicTerms.find(
            (item) => item.term_id.toString() === topicFromUrl
          );
          if (term) {
            this.buttonTopics.innerHTML = `<span 
              class="dropdown__button-text">${siteData.topic}</span>
              <span class="dropdown__button-txt-wrap">${term.name}</span>`;
          }
          this.buttonTopics.setAttribute('value', topicFromUrl);
        }

        this.changeActiveOption(this.gradeOptions, gradeFromUrl);
        this.changeActiveOption(this.subjectOptions, subjectFromUrl);
        this.changeActiveOption(this.topicOptions, topicFromUrl);

        this.searchInput.value = search;
        this.search = search;

        const data = await this.getData(
          0,
          gradeFromUrl,
          subjectFromUrl,
          topicFromUrl,
          search
        );
        if (data.length) {
          const html = this.getPostsHtml(data);
          this.wrapper.innerHTML = html;
        }
        if (this.hasMoreLessons()) {
          this.button.style.display = 'inline-block';
        } else {
          this.button.style.display = 'none';
        }

        this.isPopstateEvent = false;
      });
    },

    initSearchFormEvents() {
      if (this.searchForm) {
        this.searchForm.addEventListener('submit', async (event) => {
          event.preventDefault();
          this.search = this.searchInput.value;
          this.onFilterChange('search');
        });

        this.searchInput.addEventListener('focus', () => {
          this.searchLabel.style.display = 'block';
        });

        this.searchInput.addEventListener('blur', () => {
          this.searchLabel.style.display = 'none';
        });
      }

      if (this.searchReset) {
        this.searchReset.addEventListener('click', () => {
          this.searchInput.value = '';
          this.search = '';
          this.onFilterChange('search');
        });
      }
    },

    async getData(
      offset,
      grade = '0',
      subject = '0',
      topic = '0',
      search = null
    ) {
      let url = `${siteData.siteUrl}-json/wp/v2/lesson?per_page=${this.PER_PAGE}&offset=${offset}`;

      if (grade !== '0') {
        url += `&grade_cat=${grade}`;
      }

      if (subject !== '0') {
        url += `&subject_cat=${subject}`;
      }

      if (topic !== '0') {
        url += `&topic_cat=${topic}`;
      }

      if (search) {
        url += `&search=${search}`;
      }

      try {
        // const response = await fetch(url);
        // if (!response.ok) {
        //   throw new Error(`Response status: ${response.status}`);
        // }
        // const json = await response.json();

        // use axios instead of fetch() to get headers data
        const response = await axios(url);
        if (response.status !== 200) {
          throw new Error(`Response status: ${response.status}`);
        }

        const { data } = response;
        this.wpTotalPages = parseInt(response.headers['x-wp-totalpages'], 10);
        this.wpTotal = parseInt(response.headers['x-wp-total'], 10);

        return data;
      } catch (error) {
        // console.error(error.message);
        return [];
      }
    },

    getPostsHtml(data) {
      let html = '';
      data.forEach((el) => {
        html += `
          <a class="lessons__card" href="${el.link}">
            ${el.image}
            <h2 class="lessons__card-title">${el.title.rendered}</h2>
            <div class="lessons__card-text">${el.excerpt_short}</div>
          </a>
        `;
      });

      return html;
    },

    async onFilterChange(filter, isPopstateEvent = false) {
      this.offset = 0;
      const data = await this.getData(
        this.offset,
        this.grade,
        this.subject,
        this.topic,
        this.search
      );
      if (data.length) {
        const html = this.getPostsHtml(data);
        this.wrapper.innerHTML = html;

        if (this.hasMoreLessons()) {
          this.button.style.display = 'inline-block';
        } else {
          this.button.style.display = 'none';
        }
      } else {
        if (filter === 'search') {
          this.wrapper.innerHTML = siteData.nothingFound;
        } else {
          this.wrapper.innerHTML = '';
        }
        this.button.style.display = 'none';
      }

      if (!isPopstateEvent) {
        this.updateUrl(filter);
      }
    },

    updateUrl(filter) {
      const url = new URL(window.location);

      if (this[filter] === '0' || this[filter] === '') {
        url.searchParams.delete(filter);
        history.pushState({}, '', url.href);
      } else {
        url.searchParams.set(filter, this[filter]);
        history.pushState({}, '', url.href);
      }
    },

    changeActiveOption(dropdownOptions, valueFromUrl) {
      dropdownOptions.forEach((option) => {
        const value = option.getAttribute('data-value');
        if (valueFromUrl === value) {
          option.classList.add('active');
          option.setAttribute('aria-selected', 'true');
        } else {
          option.classList.remove('active');
          option.setAttribute('aria-selected', 'false');
        }
      });
    },

    hasMoreLessons() {
      if (this.wpTotalPages <= 1) {
        return false;
      }

      if (this.wpTotal - this.offset < this.offset) {
        return false;
      }

      return true;
    },
  };

  FilterableLessonList.init();
};
