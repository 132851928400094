/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable no-undef */

export default () => {
  const filters = document.querySelectorAll('.dropdown');
  if (filters) {
    filters.forEach((filter, filterIndex) => {
      const elements = {
        button: filter.querySelector('[role="combobox"]'),
        dropdown: filter.querySelector('[role="listbox"]'),
        options: filter.querySelectorAll('[role="option"]'),
      };

      let isDropdownOpen = false;
      let currentOptionIndex = 0;
      let lastTypedChar = '';
      let lastMatchingIndex = 0;

      const toggleDropdown = (buttonClick = false) => {
        elements.dropdown.classList.toggle('active');
        elements.button.classList.toggle('active');
        isDropdownOpen = !isDropdownOpen;
        elements.button.setAttribute(
          'aria-expanded',
          isDropdownOpen.toString()
        );

        if (isDropdownOpen) {
          focusCurrentOption(buttonClick);
          document.addEventListener('click', clickOutsideMenu);
        } else {
          elements.button.focus();
          document.removeEventListener('click', clickOutsideMenu);
        }
      };

      const clickOutsideMenu = (e) => {
        if (e.target.classList.contains('dropdown__button')) {
          const buttonFilterType = e.target.id.slice(17);
          const optionsFilterType = elements.dropdown.id.slice(18);

          // if grade === grade
          if (buttonFilterType === optionsFilterType) {
            return;
          }
        }

        if (!elements.dropdown.contains(e.target)) {
          elements.dropdown.classList.remove('active');
          elements.button.classList.remove('active');
          isDropdownOpen = false;
          elements.button.setAttribute(
            'aria-expanded',
            isDropdownOpen.toString()
          );
        }
      };

      const handleKeyPress = (event) => {
        event.preventDefault();
        const { key } = event;
        const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '];

        if (event.shiftKey && key === 'Tab') {
          switch (filterIndex) {
            case 0:
              const link = document.querySelector('.home-hero__button');
              link.focus();
              break;
            default:
              const button =
                filters[filterIndex - 1].querySelector('[role="combobox"]');
              button.focus();
          }
          return;
        }
        if (!event.shiftKey && key === 'Tab') {
          switch (filterIndex) {
            case 2:
              const search = document.querySelector('.search-form__input');
              search.focus();
              break;
            default:
              const button =
                filters[filterIndex + 1].querySelector('[role="combobox"]');
              button.focus();
          }
          return;
        }

        if (!isDropdownOpen && openKeys.includes(key)) {
          toggleDropdown();
        } else if (isDropdownOpen) {
          switch (key) {
            case 'Escape':
              toggleDropdown();
              break;
            case 'ArrowDown':
              moveFocusDown();
              break;
            case 'ArrowUp':
              moveFocusUp();
              break;
            case 'Enter':
            case ' ':
              selectCurrentOption();
              break;
            default:
              // Handle alphanumeric key presses for mini-search
              handleAlphanumericKeyPress(key);
              break;
          }
        }
      };

      const handleButtonClick = (e) => {
        if (
          e.target.classList.contains('dropdown__button-txt-wrap') ||
          e.target.classList.contains('dropdown__button-text')
        ) {
          e.stopPropagation();
        }
        toggleDropdown(true);
      };

      const handleDropdownClick = (event) => {
        // Check if the click is on an option
        const clickedOption = event.target.closest('[role="option"]');
        if (clickedOption) {
          selectOptionByElement(clickedOption);
        }
      };

      const moveFocusDown = () => {
        if (currentOptionIndex < elements.options.length - 1) {
          currentOptionIndex++;
        } else {
          currentOptionIndex = 0;
        }
        focusCurrentOption();
      };

      const moveFocusUp = () => {
        if (currentOptionIndex > 0) {
          currentOptionIndex--;
        } else {
          currentOptionIndex = elements.options.length - 1;
        }
        focusCurrentOption();
      };

      const focusCurrentOption = (buttonClick) => {
        if (buttonClick) {
          elements.options.forEach((option) => {
            option.classList.remove('current');
          });

          return;
        }

        const currentOption = elements.options[currentOptionIndex];
        const optionLabel = currentOption.textContent;

        currentOption.classList.add('current');
        currentOption.focus();

        // Scroll the current option into view
        currentOption.scrollIntoView({
          block: 'nearest',
        });

        elements.options.forEach((option, index) => {
          if (option !== currentOption) {
            option.classList.remove('current');
          }
        });
      };

      const selectCurrentOption = () => {
        const selectedOption = elements.options[currentOptionIndex];
        selectOptionByElement(selectedOption);
      };

      const selectOptionByElement = (optionElement) => {
        const optionText = optionElement.textContent;
        const optionValue = optionElement.getAttribute('data-value');

        let buttonHint = '';
        switch (filterIndex) {
          case 0:
            buttonHint = siteData.grade;
            break;
          case 1:
            buttonHint = siteData.subject;
            break;
          case 2:
            buttonHint = siteData.topic;
            break;
          default:
            buttonHint = siteData.grade;
        }

        elements.button.innerHTML = `<span 
          class="dropdown__button-text">${buttonHint}</span>
          <span class="dropdown__button-txt-wrap">${optionText}</span>
        `;
        // elements.button.textContent = optionText;
        elements.button.setAttribute('value', optionValue);
        elements.options.forEach((option) => {
          option.classList.remove('active');
          option.setAttribute('aria-selected', 'false');
        });

        optionElement.classList.add('active');
        optionElement.setAttribute('aria-selected', 'true');

        toggleDropdown();
      };

      const handleAlphanumericKeyPress = (key) => {
        const typedChar = key.toLowerCase();

        if (lastTypedChar !== typedChar) {
          lastMatchingIndex = 0;
        }

        const matchingOptions = Array.from(elements.options).filter((option) =>
          option.textContent.toLowerCase().startsWith(typedChar)
        );

        if (matchingOptions.length) {
          if (lastMatchingIndex === matchingOptions.length) {
            lastMatchingIndex = 0;
          }
          const value = matchingOptions[lastMatchingIndex];
          const index = Array.from(elements.options).indexOf(value);
          currentOptionIndex = index;
          focusCurrentOption();
          lastMatchingIndex += 1;
        }
        lastTypedChar = typedChar;
      };

      elements.button.addEventListener('keydown', handleKeyPress);
      elements.button.addEventListener('click', handleButtonClick);
      // elements.textWrapper.addEventListener('click', handleButtonClick);
      elements.dropdown.addEventListener('click', handleDropdownClick);
    });
  }
};
