export default () => {
  const start = document.querySelector('.hero__start');
  const content = document.querySelector('.content');

  if (start && content) {
    start.addEventListener('click', () => {
      content.scrollIntoView({ behavior: 'smooth' });
    });
  }
};
