/* eslint-disable @typescript-eslint/no-unused-vars */

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default () => {
  const blocks = document.querySelectorAll('.block-gallery-slider');

  if (blocks) {
    blocks.forEach((block) => {
      const prevButton = block.querySelector('.block-gallery-slider__prev');
      const nextButton = block.querySelector('.block-gallery-slider__next');

      const gallerySwiper = new Swiper(
        block.querySelector('.block-gallery-slider .swiper'),
        {
          modules: [Navigation, Pagination],
          pagination: {
            el: block.querySelector('.swiper-pagination'),
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: block.querySelector('.block-gallery-slider__next'),
            prevEl: block.querySelector('.block-gallery-slider__prev'),
          },
          on: {
            slideChange(sw) {
              if (sw.activeIndex === 0) {
                prevButton.classList.add('inActive');
              } else {
                prevButton.classList.remove('inActive');
              }

              if (sw.activeIndex === sw.slides.length - 1) {
                nextButton.classList.add('inActive');
              } else {
                nextButton.classList.remove('inActive');
              }
            },
          },
        }
      );
    });
  }
};
