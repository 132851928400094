export default () => {
  const goBackButton = document.querySelector('.hero__back');
  const goBackButtonMobile = document.querySelector('.hero__back-mobile');

  const onClick = (e) => {
    e.preventDefault();
    if (!document.referrer) {
      window.location.href = window.location.origin;
    } else {
      window.history.back();
    }
  };

  if (goBackButton) {
    goBackButton.addEventListener('click', onClick);
  }
  if (goBackButtonMobile) {
    goBackButtonMobile.addEventListener('click', onClick);
  }
};
