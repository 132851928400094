export default () => {
  const blocks = document.querySelectorAll('.block-instruction');

  if (blocks) {
    blocks.forEach((block) => {
      const button = block.querySelector('.block-instruction__heading-wrap');
      const content = block.querySelector('.block-instruction__content');
      const image = block.querySelector('.block-instruction__heading-img');

      if (window.innerWidth > 600) {
        if (content.innerHTML.length <= 91) {
          image.style.display = 'none';
        }
      }

      button.addEventListener('click', () => {
        block.classList.toggle('open');
        if (block.classList.contains('open')) {
          button.setAttribute('aria-expanded', true);
        } else {
          button.setAttribute('aria-expanded', false);
        }
      });
    });
  }
};
