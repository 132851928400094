export default () => {
  const openButton = document.querySelector('.header__toogle');
  const menu = document.querySelector('.header__menu-wrap');

  const clickOutsideMenu = (e) => {
    if (openButton.contains(e.target)) {
      return;
    }
    if (!menu.contains(e.target)) {
      menu.classList.remove('open');
      openButton.setAttribute('aria-expanded', false);
    }
  };

  if (openButton) {
    openButton.addEventListener(
      'click',
      () => {
        if (menu.classList.contains('open')) {
          menu.classList.remove('open');
          openButton.setAttribute('aria-expanded', false);
          document.removeEventListener('mousedown', clickOutsideMenu);
        } else {
          menu.classList.add('open');
          openButton.setAttribute('aria-expanded', true);
          document.addEventListener('mousedown', clickOutsideMenu);
        }
      },
      false
    );
  }
};
