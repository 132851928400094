/* eslint-disable @typescript-eslint/no-unused-vars */

import Swiper from 'swiper';

export default () => {
  const blocks = document.querySelectorAll('.block-related-lessons');

  if (blocks) {
    blocks.forEach((block) => {
      const gallerySwiper = new Swiper(
        block.querySelector('.block-related-lessons .swiper'),
        {
          slidesPerView: 'auto',
          spaceBetween: 16,
        }
      );
    });
  }
};
