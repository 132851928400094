export default () => {
  const button = document.querySelector('.hero__instructions');
  const block = document.querySelector('#block-instructions-wrapper__1');

  if (button && block) {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      block.scrollIntoView({ behavior: 'smooth' });
    });
  }
};
